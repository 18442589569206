/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const Section = (props: any): JSX.Element => {
  const { children, className } = props;
  const newProps = {
    ...props,
    className: `
      w-full  
      flex 
      flex-col 
      m-0 
      p-2 
      z-30 
      relative 
      ${className}
    `,
  };

  return (
    <div
      {...newProps}
    >
      {children}
    </div>
  );
};
