/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useCallback, useEffect } from 'react';
import { FaFacebook, FaApple, FaAt } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { useAppwrite } from '../../utils/appwrite/useAppwrite';

export const SessionModal = (): JSX.Element => {
  const appwrite = useAppwrite();
  useEffect(() => {
    appwrite.sdk?.account.get().then((r) => {
      console.log(r);
    }).catch((e) => console.log('Not logged in'));
  }, [appwrite]);

  const fbLogin = useCallback(() => {
    appwrite.sdk?.account.createOAuth2Session('facebook', 'https://usrynt.io/#success', 'https://usrynt.io/#fail');
  }, [appwrite]);

  return (
    <div id="defaultModal" data-modal-placement="center-center" tabIndex={-1} aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed self-center justify-center items-center z-50 w-full md:inset-0 h-modal md:h-full">
      <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
              Log in or register
            </h3>
            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
            </button>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="w-full p-10 pt-0">
              <button type="button" aria-label="Continue with google" role="button" className="focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-700 flex items-center w-full mt-10">
                <FcGoogle className="text-xl" style={{ fontSize: '30px' }} />
                <p className="text-base font-medium ml-4 text-gray-700">Continue with Google</p>
              </button>
              <button onClick={fbLogin} type="button" aria-label="Continue with github" role="button" className="focus:outline-none  focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-700 flex items-center w-full mt-4">
                <FaFacebook className="text-xl text-sky-700" style={{ fontSize: '30px' }} />
                <p className="text-base font-medium ml-4 text-gray-700">Continue with Facebook</p>
              </button>
              <button type="button" aria-label="Continue with twitter" role="button" className="focus:outline-none  focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-700 flex items-center w-full mt-4">
                <FaApple className="text-xl text-zinc-400" style={{ fontSize: '30px' }} />
                <div className="fb-login-button" data-size="medium" data-auto-logout-link="true" data-onlogin="checkLoginState();" />
                <p className="text-base font-medium ml-4 text-gray-700">Continue with Apple</p>
              </button>
              <button type="button" aria-label="Continue with twitter" role="button" className="focus:outline-none  focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-lg border-gray-700 flex items-center w-full mt-4">
                <FaAt className="text-xl text-zinc-400" style={{ fontSize: '30px' }} />
                <div className="fb-login-button" data-size="medium" data-auto-logout-link="true" data-onlogin="checkLoginState();" />
                <p className="text-base font-medium ml-4 text-gray-700">Login with email</p>
              </button>
              <div className="w-full flex items-center justify-between py-5">
                <hr className="w-full bg-gray-400" />
                <p className="text-base font-medium leading-4 px-2.5 text-gray-400">OR REGISTER</p>
                <hr className="w-full bg-gray-400  " />
              </div>
              <div>
                <label id="email" className="text-sm font-medium leading-none text-gray-800">
                  Email
                </label>
                <input aria-labelledby="email" type="email" className="bg-gray-200 border rounded  text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" />
              </div>
              <div className="mt-6  w-full">
                <label htmlFor="pass" className="text-sm font-medium leading-none text-gray-800">
                  Password
                </label>
                <div className="relative flex items-center justify-center">
                  <input id="pass" type="password" className="bg-gray-200 border rounded  text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2" />
                  <div className="absolute right-0 mt-2 mr-3 cursor-pointer">
                    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.99978 2C11.5944 2 14.5851 4.58667 15.2124 8C14.5858 11.4133 11.5944 14 7.99978 14C4.40511 14 1.41444 11.4133 0.787109 8C1.41378 4.58667 4.40511 2 7.99978 2ZM7.99978 12.6667C9.35942 12.6664 10.6787 12.2045 11.7417 11.3568C12.8047 10.509 13.5484 9.32552 13.8511 8C13.5473 6.67554 12.8031 5.49334 11.7402 4.64668C10.6773 3.80003 9.35864 3.33902 7.99978 3.33902C6.64091 3.33902 5.32224 3.80003 4.25936 4.64668C3.19648 5.49334 2.45229 6.67554 2.14844 8C2.45117 9.32552 3.19489 10.509 4.25787 11.3568C5.32085 12.2045 6.64013 12.6664 7.99978 12.6667ZM7.99978 11C7.20413 11 6.44106 10.6839 5.87846 10.1213C5.31585 9.55871 4.99978 8.79565 4.99978 8C4.99978 7.20435 5.31585 6.44129 5.87846 5.87868C6.44106 5.31607 7.20413 5 7.99978 5C8.79543 5 9.55849 5.31607 10.1211 5.87868C10.6837 6.44129 10.9998 7.20435 10.9998 8C10.9998 8.79565 10.6837 9.55871 10.1211 10.1213C9.55849 10.6839 8.79543 11 7.99978 11ZM7.99978 9.66667C8.4418 9.66667 8.86573 9.49107 9.17829 9.17851C9.49085 8.86595 9.66644 8.44203 9.66644 8C9.66644 7.55797 9.49085 7.13405 9.17829 6.82149C8.86573 6.50893 8.4418 6.33333 7.99978 6.33333C7.55775 6.33333 7.13383 6.50893 6.82126 6.82149C6.5087 7.13405 6.33311 7.55797 6.33311 8C6.33311 8.44203 6.5087 8.86595 6.82126 9.17851C7.13383 9.49107 7.55775 9.66667 7.99978 9.66667Z" fill="#71717A" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <button role="button" className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded hover:bg-indigo-600 py-4 w-full">Create my account</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/*

*/
