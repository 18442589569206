import React from 'react';
import { VscSettings } from 'react-icons/vsc';

export const SearchBar = (): JSX.Element => (
  <span className="w-screen md:w-1/3 h-10 bg-gray-200 cursor-pointer border border-gray-300 text-sm rounded-full flex text-black">
    <input type="search" name="serch" placeholder="Search" className="flex-grow px-4 rounded-l-full rounded-r-full text-sm focus:outline-none" />
    <div className="flex justify-center items-center w-8 text-lg">
      <VscSettings className="flex justify-center justify-self-center" />
    </div>
  </span>
);
