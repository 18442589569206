/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Footer } from '../../organisms/footer/footer';
import { Header } from '../../organisms/header/header';
import { Hero } from '../../organisms/hero/hero';
import { Section } from '../../molecules/section/section';

export const Profile = (): any => (
  <>
    <link rel="stylesheet" href="https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css" />
    <Header />
    <Hero className="items-center justify-center h-60 overflow-hidden block"><></></Hero>
    <Section className="pt-36">
      <section className="relative py-16 bg-blueGray-200 text-black">
        <div className="container mx-auto px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                  <div className="relative">
                    <img alt="..." src="https://demos.creative-tim.com/notus-js/assets/img/team-2-800x800.jpg" className=" shadow rounded-full h-auto align-middle  -mt-16  max-w-150-px" />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                  <div className="py-6 px-3 mt-32 sm:mt-0">
                    <button className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150" type="button">
                      Connect
                    </button>
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4 lg:order-1">
                  <div className="flex justify-center py-4 lg:pt-4 pt-8">
                    <div className="mr-4 p-3 text-center">
                      <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">22</span>
                      <span className="text-sm text-blueGray-400">Friends</span>
                    </div>
                    <div className="mr-4 p-3 text-center">
                      <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">10</span>
                      <span className="text-sm text-blueGray-400">Photos</span>
                    </div>
                    <div className="lg:mr-4 p-3 text-center">
                      <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">89</span>
                      <span className="text-sm text-blueGray-400">Comments</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-12">
                <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                  Jenna Stones
                </h3>
                <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                  <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400" />
                  Los Angeles, California
                </div>
                <div className="mb-2 text-blueGray-600 mt-10">
                  <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400" />
                  Guitarrist
                </div>
                <div className="mb-2 text-blueGray-600">
                  <i className="fas fa-university mr-2 text-lg text-blueGray-400" />
                  Individual
                </div>
              </div>
              <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12 px-4">
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                      An artist of considerable range, Jenna the name taken by
                      Melbourne-raised, Brooklyn-based Nick Murphy writes,
                      performs and records all of his own music, giving it a
                      warm, intimate feel with a solid groove structure. An
                      artist of considerable range.
                    </p>
                    <a href="#pablo" className="font-normal text-pink-500">Show more</a>
                  </div>
                </div>
                <div className="max-w-2xl mx-auto">

                  <div className="px-3 py-2">

                    <div className="flex justify-center gap-2 my-5">
                      <button className="bg-pink-500 px-10 py-2 rounded-full text-white shadow-lg">Follow</button>
                      <button className="bg-white border border-gray-500 px-10 py-2 rounded-full shadow-lg">Message</button>
                    </div>

                    <div className="flex justify-between items-center">
                      <button className="w-full py-2 border-b-2 border-yellow-400">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                        </svg>
                      </button>
                      <button className="w-full py-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                        </svg>
                      </button>
                    </div>

                    <div className="grid grid-cols-3 gap-2 my-3">
                      <a className="block bg-center bg-no-repeat bg-cover h-40 w-full rounded-lg" href="" style={{ backgroundImage: "url('https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg')" }} />
                      <a className="block bg-center bg-no-repeat bg-cover h-40 w-full rounded-lg" href="" style={{ backgroundImage: "url('https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg')" }} />
                      <a className="block bg-center bg-no-repeat bg-cover h-40 w-full rounded-lg" href="" style={{ backgroundImage: "url('https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg')" }} />

                      <a className="block bg-center bg-no-repeat bg-cover h-40 w-full rounded-lg" href="" style={{ backgroundImage: "url('https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg')" }} />
                      <a className="block bg-center bg-no-repeat bg-cover h-40 w-full rounded-lg" href="" style={{ backgroundImage: "url('https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg')" }} />
                      <a className="block bg-center bg-no-repeat bg-cover h-40 w-full rounded-lg" href="" style={{ backgroundImage: "url('https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg')" }} />

                      <a className="block bg-center bg-no-repeat bg-cover h-40 w-full rounded-lg" href="" style={{ backgroundImage: "url('https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg')" }} />
                      <a className="block bg-center bg-no-repeat bg-cover h-40 w-full rounded-lg" href="" style={{ backgroundImage: "url('https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg')" }} />
                      <a className="block bg-center bg-no-repeat bg-cover h-40 w-full rounded-lg" href="" style={{ backgroundImage: "url('https://images.pexels.com/photos/458766/pexels-photo-458766.jpeg')" }} />
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Section>

    <Footer />
  </>

);
