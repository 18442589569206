import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Landing } from './pages/landing/landing';
import './App.scss';
import { Profile } from './pages/profile/profile';
import { EditProfile } from './pages/edit-profile/edit-profile';
import { Home } from './pages/home-page/home-page';

export const App = (): JSX.Element => (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="profile" element={<Profile />} />
        <Route path="profile/edit" element={<EditProfile />} />
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
