/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export type AppwriteMockServiceType = {
    account: {
      get: () => Promise<null>,
      create: (_userId?: any, _email?: any, _password?: any, _name?: any) => Promise<null>,
      delete: () => Promise<null>,
      updateEmail: (_email?: any, _password?: any) => Promise<null>,
      createJWT: () => Promise<null>,
      getLogs: (_limit?: any, _offset?: any) => Promise<null>,
      updateName: (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => Promise<null>,
      updatePassword: (_password?: any, _oldPassword?: any) => Promise<null>,
      getPrefs: () => Promise<null>,
      updatePrefs: (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => Promise<null>,
      createRecovery: (_email?: any, _url?: any) => Promise<null>,
      updateRecovery: (_userId?: any, _secret?: any, _password?: any, _passwordAgain?: any) => Promise<null>,
      getSessions: () => Promise<null>,
      createSession: (_email?: any, _password?: any) => Promise<null>,
      deleteSessions: () => Promise<null>,
      createAnonymousSession: () => Promise<null>,
      createMagicURLSession: (_userId?: any, _email?: any, _url?: any) => Promise<null>,
      updateMagicURLSession: (_userId?: any, _secret?: any) => Promise<null>,
      createOAuth2Session: (_provider?: any, _success?: any, _failure?: any, _scopes?: any) => Promise<null>,
      getSession: (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => Promise<null>,
      updateSession: (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => Promise<null>,
      deleteSession: (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => Promise<null>,
      createVerification: (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => Promise<null>,
      updateVerification: (_userId?: any, _secret?: any) => Promise<null>,
    },
    database: {
      listDocuments: (_collectionId?: any, _queries?: any, _limit?: any, _offset?: any, _cursor?: any, _cursorDirection?: any, _orderAttributes?: any, _orderTypes?: any) => Promise<null>,
      createDocument: (_collectionId?: any, _documentId?: any, _data?: any, _read?: any, _write?: any) => Promise<null>,
      getDocument: (_collectionId?: any, _documentId?: any) => Promise<null>,
      updateDocument: (_collectionId?: any, _documentId?: any, _data?: any, _read?: any, _write?: any) => Promise<null>,
      deleteDocument: (_collectionId?: any, _documentId?: any) => Promise<null>,
    },
    storage: {
      listFiles: (_bucketId?: any, _search?: any, _limit?: any, _offset?: any, _cursor?: any, _cursorDirection?: any, _orderType?: any) => Promise<null>,
      createFile: (_bucketId?: any, _fileId?: any, _file?: any, _read?: any, _write?: any) => Promise<null>,
      getFile: (_bucketId?: any, _fileId?: any) => Promise<null>,
      updateFile: (_bucketId?: any, _fileId?: any, _read?: any, _write?: any) => Promise<null>,
      deleteFile: (_bucketId?: any, _fileId?: any) => Promise<null>,
      getFileDownload: (_bucketId?: any, _fileId?: any) => Promise<null>,
      getFilePreview: (_bucketId?: any, _fileId?: any, _width?: any, _height?: any, _gravity?: any, _quality?: any, _borderWidth?: any, _borderColor?: any, _borderRadius?: any, _opacity?: any, _rotation?: any, _background?: any, _output?: any) => Promise<null>,
      getFileView: (_bucketId?: any, _fileId?: any) => Promise<null>,
    },
  };

export const AppwriteMockServiceInstance: AppwriteMockServiceType = {
  account: {
    get: async () => null,
    create: async (_userId?: any, _email?: any, _password?: any, _name?: any) => null,
    delete: async () => null,
    updateEmail: async (_email?: any, _password?: any) => null,
    createJWT: async () => null,
    getLogs: async (_limit?: any, _offset?: any) => null,
    updateName: async (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => null,
    updatePassword: async (_password?: any, _oldPassword?: any) => null,
    getPrefs: async () => null,
    updatePrefs: async (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => null,
    createRecovery: async (_email?: any, _url?: any) => null,
    updateRecovery: async (_userId?: any, _secret?: any, _password?: any, _passwordAgain?: any) => null,
    getSessions: async () => null,
    createSession: async (_email?: any, _password?: any) => null,
    deleteSessions: async () => null,
    createAnonymousSession: async () => null,
    createMagicURLSession: async (_userId?: any, _email?: any, _url?: any) => null,
    updateMagicURLSession: async (_userId?: any, _secret?: any) => null,
    createOAuth2Session: async (_provider?: any, _success?: any, _failure?: any, _scopes?: any) => null,
    getSession: async (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => null,
    updateSession: async (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => null,
    deleteSession: async (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => null,
    createVerification: async (_a?: any, _b?: any, _c?: any, _d?: any, _e?: any, _f?: any) => null,
    updateVerification: async (_userId?: any, _secret?: any) => null,
  },
  database: {
    listDocuments: async (_collectionId?: any, _queries?: any, _limit?: any, _offset?: any, _cursor?: any, _cursorDirection?: any, _orderAttributes?: any, _orderTypes?: any) => null,
    createDocument: async (_collectionId?: any, _documentId?: any, _data?: any, _read?: any, _write?: any) => null,
    getDocument: async (_collectionId?: any, _documentId?: any) => null,
    updateDocument: async (_collectionId?: any, _documentId?: any, _data?: any, _read?: any, _write?: any) => null,
    deleteDocument: async (_collectionId?: any, _documentId?: any) => null,
  },
  storage: {
    listFiles: async (_bucketId?: any, _search?: any, _limit?: any, _offset?: any, _cursor?: any, _cursorDirection?: any, _orderType?: any) => null,
    createFile: async (_bucketId?: any, _fileId?: any, _file?: any, _read?: any, _write?: any) => null,
    getFile: async (_bucketId?: any, _fileId?: any) => null,
    updateFile: async (_bucketId?: any, _fileId?: any, _read?: any, _write?: any) => null,
    deleteFile: async (_bucketId?: any, _fileId?: any) => null,
    getFileDownload: async (_bucketId?: any, _fileId?: any) => null,
    getFilePreview: async (_bucketId?: any, _fileId?: any, _width?: any, _height?: any, _gravity?: any, _quality?: any, _borderWidth?: any, _borderColor?: any, _borderRadius?: any, _opacity?: any, _rotation?: any, _background?: any, _output?: any) => null,
    getFileView: async (_bucketId?: any, _fileId?: any) => null,
  },
};
