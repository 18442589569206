/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
import React, { useEffect, useRef } from 'react';
import { Navbar } from '../navbar/navbar';
import './header.scss';
import Logo from '../../assets/logo.svg';
import { SessionModal } from '../session-modal/session-modal';

export const Header = (): JSX.Element => {
  const header = useRef<HTMLDivElement>(null);
  const menu = useRef<HTMLDivElement>(null);
  const logo = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let scrollpos = window.scrollY;
    const scrollEventListener = (): void => {
      /* Apply classes for slide in bar */
      scrollpos = window.scrollY;

      if (scrollpos > 10) {
        header.current?.classList.add('bg-black');
        header.current?.classList.remove('bg-transparent');
        logo.current?.classList.add('invert-0');
        logo.current?.classList.remove('invert');
        logo.current?.classList.remove('h-28');
        logo.current?.classList.add('h-20');
        menu.current?.classList.add('text-black');
        menu.current?.classList.remove('text-white');
      } else {
        header.current?.classList.remove('bg-black');
        header.current?.classList.add('bg-transparent');
        logo.current?.classList.add('invert');
        logo.current?.classList.remove('invert-0');
        logo.current?.classList.remove('h-20');
        logo.current?.classList.add('h-28');
        menu.current?.classList.add('text-white');
        menu.current?.classList.remove('text-black');
      }
    };
    document.addEventListener('scroll', scrollEventListener);
    return () => {
      document.removeEventListener('scroll', scrollEventListener);
    };
  }, []);

  return (
    <>
      <div className="fixed w-full z-50 top-0 text-white transition-all bg-transparent" ref={header}>
        <SessionModal />
        <Navbar logo={Logo} />
      </div>
    </>
    // <nav className="fixed w-full z-50 top-0 text-white transition-all bg-transparent" ref={header}>
    //   <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2 transition-all">
    //     <div className="pl-4 flex items-center">
    //       <a className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
    //         <img alt="Usrynt Logo" ref={logo} src={Logo} className="invert h-28 transition-all" />
    //       </a>
    //     </div>
    //     <div className="block lg:hidden pr-4">
    //       <button id="nav-toggle" className="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
    //         <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    //           <title>Menu</title>
    //           <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    //         </svg>
    //       </button>
    //     </div>
    //     <div ref={menu} className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 lg:bg-transparent p-4 lg:p-0 z-20" id="nav-content">
    //       <ul className="list-reset lg:flex justify-end flex-1 items-center">
    //         <li className="mr-3">
    //           <a className="inline-block no-underline py-2 px-4 usrynt-text-primary font-bold text-xl  active" href="#">Active</a>
    //         </li>
    //         <li className="mr-3">
    //           <a className="inline-block no-underline py-2 px-4 usrynt-text-primary font-bold text-xl" href="#">link</a>
    //         </li>
    //         <li className="mr-3">
    //           <a className="inline-block no-underline py-2 px-4 usrynt-text-primary font-bold text-xl" href="#">link</a>
    //         </li>
    //       </ul>
    //       <button
    //         id="navAction"
    //         className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
    //         type="button"
    //         data-modal-toggle="defaultModal"
    //       >
    //         Register
    //       </button>
    //     </div>
    //   </div>
    // </nav>
  );
};
