import { Appwrite } from 'appwrite';
import { AppwriteMockServiceInstance, AppwriteMockServiceType } from './apwrite-sdk.mock';

export class AppwriteService {
    private appwriteSdk: Appwrite | AppwriteMockServiceType;

    constructor() {
      if (process.env.REACT_APP_APPWRITE_SDK === 'mock') {
        this.appwriteSdk = AppwriteMockServiceInstance;
      } else {
        this.appwriteSdk = new Appwrite();

        this.appwriteSdk?.setEndpoint(
          'https://usrynt.io/v1',
        ).setProject(
          '62505cbf82e9ef4805b1',
        );
      }
    }

    get sdk(): Appwrite | AppwriteMockServiceType | undefined {
      return this.appwriteSdk;
    }
}

export const AppwriteInstance = new AppwriteService();
