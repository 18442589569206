/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/button-has-type */
import React from 'react';
import Logo from '../../assets/logo_usrynt.png';
import { Section } from '../../molecules/section/section';
import Video from './assets/video.mp4';
import './hero.scss';

export const Hero = ({ className, children }: any): JSX.Element => (
  <Section id="hero" className={`items-center justify-center h-screen ${className}`}>
    {children}
    {(!children && (
      <div className="relative flex flex-col w-1/2 mx-auto container">
        <img className=" w-80 self-center" src={Logo} alt="Logo" />
        <h1 className="my-4 text-3xl font-bold leading-tight">
          FOR ARTISTS
        </h1>
        <p className="leading-normal text-2xl mb-8">
          We were made for sharing you, Baby, you were made for sharing it…
        </p>
      </div>
    ))}

  </Section>
);
