/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React from 'react';
import { Dropdown, Avatar, Navbar as FlowBiteNavbar } from 'flowbite-react';
import { SearchBar } from '../../molecules/search-bar/search-bar';
import { MenuCapsule } from '../../molecules/menu-capsule/menu-capsule';
import './navbar.scss';

export const Navbar = (props: any): JSX.Element => {
  const { logo } = props;
  return (
    <div
      className="sticky top-0 flex lg:justify-center justify-between items-center w-full text-black bg-usrynt shadow"
      style={{
        zIndex: 999,
      }}
    >
      <FlowBiteNavbar
        fluid
        rounded
        className="w-full z-50 bg-usrynt text-black rounded-none"
      >
        <FlowBiteNavbar.Brand href="https://flowbite.com/">
          <img
            src={logo}
            className="mr-3 h-16"
            alt="Flowbite Logo"
          />
        </FlowBiteNavbar.Brand>

        <div className="flex md:order-2">

          <button
            className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
            type="button"
            data-modal-toggle="defaultModal"
          >
            Login or Register
          </button>

        </div>
        <SearchBar />
      </FlowBiteNavbar>
    </div>
  );
};

/*
<div className="flex mr-4 items-center">
            <a className=" inline-block py-2 px-3 hover:bg-orange-100 rounded-full" href="#">
              <div className="flex items-center relative cursor-pointer whitespace-nowrap text-amber-600 hover:text-amber-600">Are you a musician?</div>
            </a>
            <div className="block relative">
              <button type="button" className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full relative">
                <div className="flex items-center h-5">
                  <div className="_xpkakx">
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{
                        display: 'block',
                        height: '16px',
                        width: '16px',
                        fill: 'gray',
                      }}
                    >
                      <path d="m8.002.25a7.77 7.77 0 0 1 7.748 7.776 7.75 7.75 0 0 1 -7.521 7.72l-.246.004a7.75 7.75 0 0 1 -7.73-7.513l-.003-.245a7.75 7.75 0 0 1 7.752-7.742zm1.949 8.5h-3.903c.155 2.897 1.176 5.343 1.886 5.493l.068.007c.68-.002 1.72-2.365 1.932-5.23zm4.255 0h-2.752c-.091 1.96-.53 3.783-1.188 5.076a6.257 6.257 0 0 0 3.905-4.829zm-9.661 0h-2.75a6.257 6.257 0 0 0 3.934 5.075c-.615-1.208-1.036-2.875-1.162-4.686l-.022-.39zm1.188-6.576-.115.046a6.257 6.257 0 0 0 -3.823 5.03h2.75c.085-1.83.471-3.54 1.059-4.81zm2.262-.424c-.702.002-1.784 2.512-1.947 5.5h3.904c-.156-2.903-1.178-5.343-1.892-5.494l-.065-.007zm2.28.432.023.05c.643 1.288 1.069 3.084 1.157 5.018h2.748a6.275 6.275 0 0 0 -3.929-5.068z" />
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>
*/

/*
<Dropdown
            arrowIcon={false}
            inline
            label={<MenuCapsule />}
          >
            <Dropdown.Header>
              <span className="block text-sm">
                Bonnie Green
              </span>
              <span className="block truncate text-sm font-medium">
                name@flowbite.com
              </span>
            </Dropdown.Header>
            <Dropdown.Item>
              Dashboard
            </Dropdown.Item>
            <Dropdown.Item>
              Settings
            </Dropdown.Item>
            <Dropdown.Item>
              Earnings
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
              Sign out
            </Dropdown.Item>
          </Dropdown>
*/
